import React from 'react'

interface Props {
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
}

const SIZE_CLASSES = {
  xs: 'h-4 w-4',
  sm: 'h-8 w-8',
  md: 'h-14 w-14',
  lg: 'h-24 w-24',
  xl: 'h-36 w-36'
}

export const LoadingSpinner: React.FC<Props> = ({
  size = 'sm'
}): React.ReactElement => {
  const spinnerColor = 'rgba(29, 78, 216, 1)'

  return (
    <div className={`${SIZE_CLASSES[size]} animate-spin ease-linear`}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        fill={spinnerColor}
        viewBox='0 0 300 300.35'
      >
        <path
          d='M1.13,126.89c.74-2.89,1.58-5.77,2.21-8.69A150.52,150.52,0,0,1,123.85,1.3c2.08-.38,4.19-.57,6.52-.87.1,1.29.25,2.27.25,3.25,0,11.12,0,22.24.06,33.36,0,2.37-.55,3.47-3.11,4.13q-66.79,17.13-84,84c-.61,2.38-1.6,3-3.85,3-11.5-.08-23,0-34.5,0-1.38,0-2.75.19-4.13.29Z'
          transform='translate(-1.13 -0.43)'
        />
        <path
          d='M1.82,169.55c14,0,26.83,0,39.7,0,2.7,0,2.41,2.06,2.8,3.67,7,28.81,22.32,52.14,47.49,67.78,39.08,24.29,78.91,24.17,118.64.93l3.67-2.17,29,30.53c-46.37,31.79-96.58,40.28-149.58,18.07C41.31,266.46,11.78,225.74,1.82,169.55Z'
          transform='translate(-1.13 -0.43)'
        />
        <path
          d='M271.56,238.63,242.79,210a112.26,112.26,0,0,0,16-37c13.48-58.15-23-117.23-81-131.23-5.68-1.37-5.68-1.37-5.68-7.34V.73c40.38,4.15,94.37,32.76,118.26,91.66C311.36,144.2,299.84,204.58,271.56,238.63Z'
          transform='translate(-1.13 -0.43)'
        />
      </svg>
    </div>
  )
}
