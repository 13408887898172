import React from 'react'

import type { NumberInputType } from '../../../../types/calculation-inputs.types'

interface Props {
  value: string | number
  hasError: boolean
  format?: string
  inputType: NumberInputType
  onChange: (newValue: string | number) => void
}

export const NumericInput: React.FC<Props> = ({
  format = null,
  hasError,
  value,
  inputType,
  onChange
}): React.ReactElement => {
  const { range } = inputType

  return (
    <div className='flex shadow-sm rounded-md'>
      <input
        className={`${
          format ? 'rounded-l-md' : 'rounded-md'
        } relative flex-1 w-full bg-white border border-gray-300 pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-blue-600 focus:border-blue-600 ${
          hasError ? 'border-red-700' : ''
        }`}
        type='number'
        min={range?.min?.value}
        max={range?.max?.value}
        onChange={(e) => onChange(Number(e.target.value))}
        value={value}
        data-cy={`${hasError ? 'input-has-error' : ''}`}
      />
      {format && (
        <span className='w-36 inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-100 text-gray-500 text-sm'>
          {format}
        </span>
      )}
    </div>
  )
}
