import React from 'react'

interface Props {
  size?: 'xs' | 'normal'
  color?: 'gray' | 'blue' | 'white' | 'indigo'
  type?: 'button' | 'submit'
  svgIcon?: React.ReactFragment
  fluid?: boolean
  label: string
  isLoading?: boolean
  onClick: () => void
}

const SIZE_CLASSES = {
  xs: 'py-3 px-4 text-xs font-semibold',
  normal: 'py-3 px-6 font-medium'
}

const COLOR_CLASSES = {
  white: 'border border-gray-300 text-gray-700 bg-white hover:bg-gray-50',
  gray: 'border border-gray-300 bg-gray-300 hover:bg-gray-400 hover:border-gray-400 text-gray-800',
  blue: 'border border-blue-600 bg-blue-600 hover:bg-blue-700 hover:border-blue-700 text-white',
  indigo:
    'border border-indigo-600 bg-indigo-600 hover:bg-indigo-800 hover:border-indigo-800 text-white'
}

const FLUID_CLASSES = 'w-full text-center justify-center'

const DISABLED_CLASSES = 'cursor-not-allowed opacity-70'

export const Button: React.FC<Props> = ({
  size = 'normal',
  color = 'white',
  type = 'button',
  fluid = false,
  svgIcon = undefined,
  isLoading = false,
  label,
  onClick
}): React.ReactElement => {
  return (
    <button
      type={type === 'button' ? 'button' : 'submit'}
      onClick={(e) => {
        e.preventDefault()
        onClick()
      }}
      className={`${COLOR_CLASSES[color]} ${SIZE_CLASSES[size]} ${
        isLoading ? DISABLED_CLASSES : ''
      } ${
        fluid ? FLUID_CLASSES : ''
      } shadow-sm rounded-lg inline-flex items-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600`}
      disabled={isLoading}
    >
      {isLoading && (
        <svg
          className='animate-spin -ml-1 mr-3 h-5 w-5 text-white'
          xmlns='http://www.w3.org/2000/svg'
          fill='none'
          viewBox='0 0 24 24'
        >
          <circle
            className='opacity-25'
            cx={12}
            cy={12}
            r={10}
            stroke='currentColor'
            strokeWidth={4}
          />
          <path
            className='opacity-75'
            fill='currentColor'
            d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
          />
        </svg>
      )}
      {svgIcon && !isLoading && svgIcon}
      <span>{label}</span>
    </button>
  )
}
