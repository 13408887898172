import React from 'react'

interface Props {
  color:
    | 'grey'
    | 'red'
    | 'orange'
    | 'green'
    | 'teal'
    | 'blue'
    | 'indigo'
    | 'white'
  title: string
  uppercase?: boolean
}

const COLOR_CLASSES = {
  grey: 'text-gray-500 bg-gray-200',
  red: 'text-red-600 bg-red-100',
  orange: 'text-orange-600 bg-orange-200',
  green: 'text-green-600 bg-green-100',
  teal: 'text-teal-600 bg-teal-200',
  blue: 'text-blue-600 bg-blue-200',
  indigo: 'text-indigo-300 bg-indigo-50',
  white: 'text-gray-600 bg-white border border-gray-200'
}

export const Badge: React.FC<Props> = ({
  color,
  title,
  uppercase = true
}): React.ReactElement => {
  return (
    <span
      className={`${COLOR_CLASSES[color]} ${
        uppercase ? 'uppercase' : ''
      } text-xs font-semibold inline-block py-1 px-2 rounded-full last:mr-0 mr-1`}
    >
      {title}
    </span>
  )
}
