import React, { ReactNode } from 'react'

interface PanelProps {
  title?: string
  subtitle?: string
  children: ReactNode
}

export const Panel: React.FC<PanelProps> = ({
  title,
  subtitle,
  children
}): React.ReactElement => {
  return (
    <div className='bg-white shadow overflow-hidden rounded-lg border border-gray-200 py-8'>
      {title && (
        <div className='bg-white px-6 pb-8 sm:px-8 border-b border-gray-200'>
          <h2 className='text-xl leading-6 font-medium text-gray-900'>
            {title}
          </h2>
          <h3 className='mt-1.5 max-w-2xl text-gray-500'>{subtitle}</h3>
        </div>
      )}
      {children}
    </div>
  )
}
