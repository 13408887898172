import React from 'react'
import { Link } from 'react-router-dom'

import type { BreadcrumbsType } from '../../../../types/types'

interface BreadcrumbsProps {
  breadcrumbs: BreadcrumbsType[]
}

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
  breadcrumbs
}): React.ReactElement => {
  return (
    <nav className='bg-grey-light rounded-md w-full py-4'>
      <ol className='list-reset flex'>
        <li className='inline-flex items-center'>
          <a
            href='/'
            className='inline-flex items-center text-sm text-gray-400 hover:text-gray-900'
          >
            <svg
              className='w-6 h-6'
              fill='currentColor'
              viewBox='0 0 20 20'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path d='M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z' />
            </svg>
          </a>
        </li>
        {breadcrumbs.map((breadcrumb, index, arr) => (
          <>
            <li className='inline-flex items-center'>
              <span className='text-gray-400 text-xl px-6 font-light'>/</span>
              {/* Last element does not be a link */}
              {arr.length - 1 !== index ? (
                <Link
                  to={breadcrumb.url}
                  className='text-gray-500 hover:text-blue-700 font-medium'
                >
                  {breadcrumb.text}
                </Link>
              ) : (
                <span className='text-gray-500 font-medium'>
                  {breadcrumb.text}
                </span>
              )}
            </li>
          </>
        ))}
      </ol>
    </nav>
  )
}
