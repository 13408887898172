/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'

import { useCopySelectOptions } from '../../../../hooks/useCopySelectOptions'
import type {
  AvailableLanguages,
  LabelType
} from '../../../../types/localization.types'
import { viewLocalizedText } from '../../../../utils/viewLocalizedText'
import { CopyButton } from '../../../Button/variants/CopyButton'

interface Props {
  inputId: string
  language: AvailableLanguages
  placeholder?: string
  value: string | number
  hasError: boolean
  format: string
  options: Array<{
    value: string | number
    label?: LabelType
  }>
  type: 'string' | 'number'
  onChange: (newValue: string | number | boolean) => void
}

export const SingleSelect: React.FC<Props> = ({
  inputId,
  language,
  value,
  // hasError,
  format,
  options,
  type,
  onChange
}): React.ReactElement => {
  const copiedOptions = useCopySelectOptions(options)

  const onSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (type === 'string') {
      const val = e.target.value.toString()

      return onChange(val)
    }

    if (type === 'number') {
      const val = Number(e.target.value)

      return onChange(val)
    }

    return onChange(e.target.value)
  }

  return (
    <div className='flex flex-col mt-4'>
      <div className='flex flex-col'>
        {options.map((option) => (
          <div
            className='flex items-center flex-start pb-5'
            key={`${inputId}-${option.value.toString()}`}
          >
            <input
              id={`${inputId}-${option.value.toString()}`}
              name={inputId}
              type='radio'
              className='focus:ring-blue-600 h-5 w-5 text-blue-600 border-gray-300'
              value={option.value.toString()}
              onChange={(e) => onSelect(e)}
              checked={option.value === value}
            />
            <label
              htmlFor={`${inputId}-${option.value.toString()}`}
              className='ml-4 block font-medium text-gray-700'
            >
              {viewLocalizedText({
                fallback: '',
                label: option.label,
                language
              }) === ''
                ? option.value.toString()
                : `${viewLocalizedText({
                    fallback: '',
                    label: option.label,
                    language
                  })} (${option.value.toString()})`}
            </label>
          </div>
        ))}
      </div>
      {format && (
        <span className='w-36 inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-100 text-gray-500 text-sm'>
          {format}
        </span>
      )}
      <div>
        <CopyButton
          contentToCopy={copiedOptions}
          copyText='Copy answer options'
        />
      </div>
    </div>
  )
}
