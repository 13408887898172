import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { Button } from '../../components/Button/Button'
import { PageLayout } from '../../components/Layout/PageLayout'
import { LoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner'
import { Panel } from '../../components/Panel/Panel'
import { LanguageContext } from '../../context/LanguageContext'
import { getCalculation } from '../../services/calculation.service'
import type { CalculationType } from '../../types/types'
import { viewLocalizedText } from '../../utils/viewLocalizedText'

export const DocumentationPage: React.FC = (): React.ReactElement => {
  const { calculationId } = useParams<{ calculationId: string }>()
  const { userLanguage } = useContext(LanguageContext)
  const history = useHistory()

  const [isLoading, setIsLoading] = useState(true)
  const [calculation, setCalculation] = useState<CalculationType>()

  useEffect(() => {
    let isMounted = true

    getCalculation(calculationId)
      .then((res) => {
        if (isMounted) {
          setCalculation(res)
          setIsLoading(false)
        }

        return null
      })
      // eslint-disable-next-line no-console
      .catch((e) => console.log(e))
    return () => {
      isMounted = false
    }
  }, [calculationId])

  if (isLoading)
    return (
      <div className='bg-white overflow-hidden flex justify-center h-screen rounded-lg border border-gray-200'>
        <div className='w-11/12 max-w-4xl py-8 flex justify-center items-center'>
          <LoadingSpinner size='lg' />
        </div>
      </div>
    )

  const breadcrumbs = [
    {
      url: '/',
      text: 'Scores'
    },
    {
      url: `/calculations/${calculationId}`,
      text: viewLocalizedText({
        fallback: '',
        label: calculation?.calculation_name,
        language: userLanguage
      })
    },
    {
      url: '',
      text: 'Documentation'
    }
  ]

  return (
    <PageLayout breadcrumbs={breadcrumbs}>
      <div className='lg:flex lg:items-center lg:justify-between pb-10'>
        <div className='flex-1 min-w-0'>
          <h1 className='text-3xl text-gray-900 font-semibold'>
            Documentation of{' '}
            {viewLocalizedText({
              fallback: '',
              label: calculation?.calculation_name,
              language: userLanguage
            })}
          </h1>
        </div>
        <div className='mt-3 lg:mt-0'>
          <div className='flex'>
            <div>
              <Button
                label='Back to calculation'
                onClick={() => history.push(`/calculations/${calculationId}`)}
              />
            </div>
          </div>
        </div>
      </div>
      <Panel>
        <div
          className='markdown hide-calculation-title px-6 sm:px-8 pb-2'
          aria-hidden='true'
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: viewLocalizedText({
              fallback: '',
              label: calculation?.calculation_description,
              language: userLanguage
            })
          }}
        />
      </Panel>
    </PageLayout>
  )
}
