import React from 'react'
import { isEmpty } from 'ramda'

import type {
  ApiErrorType,
  CalculationBlueprintType,
  CalculationInputType
} from '../../types/types'
import { Alert } from '../Alert/Alert'
import { Input } from './Form/Input'

interface Props {
  error: ApiErrorType
  calculationBlueprint?: CalculationBlueprintType
  calculationInputData: CalculationInputType
  onDataInput: (input_id: string) => (newValue: string | number) => void
}

export const CalculationExecution: React.FC<Props> = ({
  error,
  calculationBlueprint,
  calculationInputData,
  onDataInput
}): React.ReactElement => {
  const { invalid_inputs: invalidInputs = [] } = error

  return (
    <form>
      <div className='flex flex-col mt-2 pb-8'>
        <div className='mx-8'>
          {!isEmpty(error) && (
            <div className='mt-4' data-cy='calculation-error'>
              <Alert
                type='error'
                title={error.message}
                listItems={invalidInputs.map(({ input_id }) => input_id)}
              />
            </div>
          )}
          {calculationBlueprint?.input_definition.map((input) => {
            return (
              <Input
                key={input.id}
                inputId={input.id}
                label={input.label}
                inputError={invalidInputs.find(
                  (invalidInput) => invalidInput.input_id === input.id
                )}
                info={input.info}
                // @ts-expect-error
                onChange={onDataInput(input.id)}
                value={calculationInputData[input.id]}
                format={input.format}
                inputType={input.input_type}
              />
            )
          })}
        </div>
      </div>
    </form>
  )
}
