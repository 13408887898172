import React, { useState } from 'react'

import { menuItems } from '../../../../shared/constants/menu'
import { Container } from '../../Container/Container'
import { SelectLanguage } from '../SelectLanguage/SelectLanguage'
import { DesktopMenu, MobileMenu } from './Menu'

export const Nav: React.FC = (): React.ReactElement => {
  const [menuIsOpen, setMenuIsOpen] = useState(false)

  return (
    <nav className='bg-white'>
      <Container>
        <div className='border-b border-gray-200 relative flex items-center justify-evenly sm:justify-between h-20'>
          <div className='absolute inset-y-0 left-0 flex items-center sm:hidden'>
            {/* Mobile menu button */}
            <button
              className='inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'
              aria-expanded='false'
              type='button'
              onClick={() => setMenuIsOpen(!menuIsOpen)}
            >
              <span className='sr-only'>Open main menu</span>
              {/* Icon when menu is closed. */}
              <svg
                className={`block h-6 w-6 ${menuIsOpen ? 'hidden' : 'block'}`}
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'
                aria-hidden='true'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth={2}
                  d='M4 6h16M4 12h16M4 18h16'
                />
              </svg>
              {/* Icon when menu is open. */}
              <svg
                className={`block h-6 w-6 ${menuIsOpen ? 'block' : 'hidden'}`}
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'
                aria-hidden='true'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth={2}
                  d='M6 18L18 6M6 6l12 12'
                />
              </svg>
            </button>
          </div>
          <div className='ml-4 flex-1 flex items-center justify-center sm:items-stretch sm:justify-start sm:ml-0'>
            <div className='flex-shrink-0 flex items-center'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='rgba(29, 78, 216, 1)'
                className='block lg:hidden h-10 w-auto'
                viewBox='0 0 300 300.35'
              >
                <path
                  d='M1.13,126.89c.74-2.89,1.58-5.77,2.21-8.69A150.52,150.52,0,0,1,123.85,1.3c2.08-.38,4.19-.57,6.52-.87.1,1.29.25,2.27.25,3.25,0,11.12,0,22.24.06,33.36,0,2.37-.55,3.47-3.11,4.13q-66.79,17.13-84,84c-.61,2.38-1.6,3-3.85,3-11.5-.08-23,0-34.5,0-1.38,0-2.75.19-4.13.29Z'
                  transform='translate(-1.13 -0.43)'
                />
                <path
                  d='M1.82,169.55c14,0,26.83,0,39.7,0,2.7,0,2.41,2.06,2.8,3.67,7,28.81,22.32,52.14,47.49,67.78,39.08,24.29,78.91,24.17,118.64.93l3.67-2.17,29,30.53c-46.37,31.79-96.58,40.28-149.58,18.07C41.31,266.46,11.78,225.74,1.82,169.55Z'
                  transform='translate(-1.13 -0.43)'
                />
                <path
                  d='M271.56,238.63,242.79,210a112.26,112.26,0,0,0,16-37c13.48-58.15-23-117.23-81-131.23-5.68-1.37-5.68-1.37-5.68-7.34V.73c40.38,4.15,94.37,32.76,118.26,91.66C311.36,144.2,299.84,204.58,271.56,238.63Z'
                  transform='translate(-1.13 -0.43)'
                />
              </svg>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='rgba(29, 78, 216, 1)'
                className='hidden lg:block h-10 w-auto'
                viewBox='0 0 300 300.35'
              >
                <path
                  d='M1.13,126.89c.74-2.89,1.58-5.77,2.21-8.69A150.52,150.52,0,0,1,123.85,1.3c2.08-.38,4.19-.57,6.52-.87.1,1.29.25,2.27.25,3.25,0,11.12,0,22.24.06,33.36,0,2.37-.55,3.47-3.11,4.13q-66.79,17.13-84,84c-.61,2.38-1.6,3-3.85,3-11.5-.08-23,0-34.5,0-1.38,0-2.75.19-4.13.29Z'
                  transform='translate(-1.13 -0.43)'
                />
                <path
                  d='M1.82,169.55c14,0,26.83,0,39.7,0,2.7,0,2.41,2.06,2.8,3.67,7,28.81,22.32,52.14,47.49,67.78,39.08,24.29,78.91,24.17,118.64.93l3.67-2.17,29,30.53c-46.37,31.79-96.58,40.28-149.58,18.07C41.31,266.46,11.78,225.74,1.82,169.55Z'
                  transform='translate(-1.13 -0.43)'
                />
                <path
                  d='M271.56,238.63,242.79,210a112.26,112.26,0,0,0,16-37c13.48-58.15-23-117.23-81-131.23-5.68-1.37-5.68-1.37-5.68-7.34V.73c40.38,4.15,94.37,32.76,118.26,91.66C311.36,144.2,299.84,204.58,271.56,238.63Z'
                  transform='translate(-1.13 -0.43)'
                />
              </svg>
            </div>
            <DesktopMenu menuItems={menuItems} />
          </div>
          <div>
            <SelectLanguage />
          </div>
        </div>
      </Container>
      <MobileMenu menuItems={menuItems} menuIsOpen={menuIsOpen} />
    </nav>
  )
}
