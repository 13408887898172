import type { MenuItem } from '../../types/types'
import { routes } from './routes'

export const menuItems: MenuItem[] = [
  {
    label: 'Scores',
    path: routes.HOME.path,
    exact: true,
    cypressSelector: 'menu-button-calculations'
  }
  // {
  //   label: 'About',
  //   path: routes.ABOUT.path,
  //   exact: true,
  //   cypressSelector: 'menu-button-about'
  // }
]
