import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'

import { LanguageContext } from '../../context/LanguageContext'
import type { CalculationsLibraryType } from '../../types/types'
import { filterCalculationsByCalculationName } from '../../utils/filterCalculationsByCalculationName'
import { viewLocalizedText } from '../../utils/viewLocalizedText'

interface Props {
  calculations: CalculationsLibraryType
  filter: {
    calculationNameSearchString: string
  }
}

export const CalculationsTable: React.FC<Props> = ({
  calculations,
  filter
}): React.ReactElement => {
  const { userLanguage } = useContext(LanguageContext)
  const history = useHistory()
  const { t } = useTranslation()

  const { calculationNameSearchString } = filter

  const filteredCalulations =
    calculationNameSearchString === ''
      ? calculations
      : filterCalculationsByCalculationName(calculationNameSearchString)(
          calculations
        )

  return (
    <div className='overflow-x-auto -mx-6 sm:-mx-8'>
      <div className='py-2 align-middle inline-block min-w-full px-6 sm:px-8'>
        <div className='overflow-hidden border-b border-gray-40'>
          <table
            className='table-fixed min-w-full'
            data-cy='calculations-table'
          >
            <thead className='bg-gray-100 border-b border-gray-200'>
              <tr>
                <th
                  scope='col'
                  className='px-8 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider'
                >
                  {t('pages.calculations_list.calculations_table.columns.name')}
                </th>
                <th
                  scope='col'
                  className='px-8 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider'
                >
                  {t(
                    'pages.calculations_list.calculations_table.columns.function'
                  )}
                </th>
                <th
                  scope='col'
                  className='w-1/6 relative px-8 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider'
                >
                  <span className='sr-only'>
                    {t(
                      'pages.calculations_list.calculations_table.columns.options'
                    )}
                  </span>
                </th>
              </tr>
            </thead>
            <tbody className='bg-white'>
              {filteredCalulations.map((calculation, i) => {
                const calculationId = calculation.calculation_id
                const calculationName = calculation.calculation_name

                // eslint-disable-next-line no-magic-numbers
                const isEvenRow = i % 2 === 0

                return (
                  <tr
                    key={calculationId}
                    className={`${
                      !isEvenRow ? 'bg-gray-50 hover:bg-blue-50' : ''
                    } cursor-pointer hover:bg-blue-50`}
                    onClick={() =>
                      history.push(`/calculations/${calculationId}`)
                    }
                  >
                    <td className='px-8 py-5'>
                      <div className='font-medium text-gray-800'>
                        {viewLocalizedText({
                          fallback: '',
                          label: calculationName,
                          language: userLanguage
                        })}
                      </div>
                    </td>
                    <td className='px-8 py-5 text-gray-500'>{calculationId}</td>
                    <td className='px-8 py-5 text-left font-medium'>
                      <Link
                        to={`/calculations/${calculationId}`}
                        className='text-blue-700 hover:underline'
                      >
                        View details
                      </Link>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
