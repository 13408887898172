import { useContext } from 'react'

import { LanguageContext } from '../context/LanguageContext'
import type { LabelType } from '../types/localization.types'

type OptionsType = Array<{
  value: string | number
  label?: LabelType
}>

export const useCopySelectOptions = (options: OptionsType): string => {
  const { userLanguage } = useContext(LanguageContext)

  return options
    .map((option) => {
      return `${option.value}=${
        (option.label && option.label[userLanguage]) || 'No label found'
      }`
    })
    .join('\n')
}
