// eslint-disable-next-line simple-import-sort/imports
import { filter } from 'ramda'

import type { CalculationType, CalculationsLibraryType } from '../types/types'

export const filterCalculationsByCalculationName =
  (calculationNameSearchString: string) =>
  (calculations: CalculationsLibraryType): CalculationsLibraryType => {
    const byCalculationName = (calculation: CalculationType): boolean => {
      /**
       * To do: search based on calculation name should
       * take into account the language of the user.
       * By default it now only searches in English
       */
      const calculationName = calculation.calculation_name.en

      return calculationName
        .toLowerCase()
        .includes(calculationNameSearchString.toLowerCase())
    }

    return filter(byCalculationName, calculations)
  }
