// @ts-nocheck
interface Api {
  url: string
  endpoints: {
    [action: string]: (calculationId?: string) => string
  }
}

const { REACT_APP_CALCULATION_SUITE_API_URL = '' } = process.env

export const api: Readonly<Api> = Object.freeze({
  url: REACT_APP_CALCULATION_SUITE_API_URL,
  endpoints: {
    GET_SINGLE_CALCULATION: (calculationId) => `/calculations/${calculationId}`, // GET
    GET_ALL_CALCULATIONS: () => '/calculations', // GET
    EXECUTE_CALCULATION: () => '/calculations', // POST
    SIMULATE_CALCULATION: (calculationId) =>
      `/calculations/simulate/${calculationId}`, // GET
    UPLOAD_CSV_FOR_CALCULATION: (calculationId) =>
      `/calculations/${calculationId}/csv`, // POST
    DOWNLOAD_CSV_FILE: (fileName) =>
      `/downloads/csv_with_calculation_results/${fileName}` // GET
  }
})
