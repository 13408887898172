import React, { useState } from 'react'

import type { LabelType as LocalizeableContent } from '../types/localization.types'

type SideOverContextStateType = {
  isOpen: boolean
  title?: LocalizeableContent
  content?: LocalizeableContent
}

export interface SideOverContextState {
  state: SideOverContextStateType
  setState: (newState: SideOverContextStateType) => void
}

const contextDefaultValues: SideOverContextState = {
  state: {
    isOpen: false,
    title: { en: '', nl: '', fr: '', de: '' },
    content: { en: '', nl: '', fr: '', de: '' }
  },
  setState: () => {}
}

const SideOverContext =
  React.createContext<SideOverContextState>(contextDefaultValues)

const SideOverProvider: React.FC = ({ children }): React.ReactElement => {
  const [state, setState] = useState<SideOverContextStateType>(
    contextDefaultValues.state
  )

  return (
    <SideOverContext.Provider value={{ state, setState }}>
      {children}
    </SideOverContext.Provider>
  )
}

export { SideOverContext, SideOverProvider }
