import React, { ReactNode } from 'react'

import type { BreadcrumbsType } from '../../types/types'
import { Container } from './Container/Container'
import { Footer } from './Footer/Footer'
import { Breadcrumbs } from './Header/Breadcrumb/Breadcrumb'
import { Nav } from './Header/Nav/Nav'
import { SideOver } from './SideOver/SideOver'

interface PageLayoutProps {
  children: ReactNode
  breadcrumbs: BreadcrumbsType[]
}

export const PageLayout: React.FC<PageLayoutProps> = ({
  children,
  breadcrumbs
}): React.ReactElement => {
  return (
    <div className='flex flex-col min-h-screen'>
      <header className='border-b border-gray-200 shadow-lg'>
        <Nav />
        <Container>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </Container>
      </header>
      <main className='relative flex-1 bg-gray-100 w-full'>
        <Container className='py-12'>{children}</Container>
      </main>
      <Footer />
      <SideOver />
    </div>
  )
}
