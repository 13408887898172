import React, { useState } from 'react'
import { isEmpty } from 'ramda'
import { Transition } from '@headlessui/react'

import { ICONS } from './Icons'

interface Props {
  type: 'error' | 'info' | 'warning' | 'success'
  title: string
  content?: string
  listItems?: string[]
}

const TYPE_CLASSES = {
  error: 'text-red-700 bg-red-50',
  info: 'bg-blue-50 text-blue-700',
  warning: 'bg-yellow-50 text-yellow-700',
  success: 'bg-green-50 text-green-600'
}

const CLOSE_BTN_HOVER_CLASSES = {
  error: 'hover:text-red-200',
  info: 'hover:text-blue-200',
  warning: 'hover:text-yellow-300',
  success: 'hover:text-green-400'
}

export const Alert: React.FC<Props> = ({
  type,
  title,
  content = '',
  listItems = []
}): React.ReactElement => {
  const [isOpen, setIsOpen] = useState<boolean>(true)

  return (
    <Transition
      show={isOpen}
      enter='ease-out duration-300'
      enterFrom='opacity-0 scale-95'
      enterTo='opacity-100 scale-100'
      leave='ease-in duration-200'
      leaveFrom='opacity-100 scale-100'
      leaveTo='opacity-0 scale-95'
      className={`${TYPE_CLASSES[type]} relative px-4 py-3 leading-normal rounded-lg`}
    >
      <div role='alert'>
        {ICONS[type] && (
          <span className='absolute inset-y-0 left-0 flex mt-4 ml-4'>
            {ICONS[type]}
          </span>
        )}
        <div className={`${ICONS[type] ? 'ml-8 mr-6' : 'mx-6'}`}>
          <p className='font-medium'>{title}</p>
          {!isEmpty(content) && <p className='text-base'>{content}</p>}
          {listItems && (
            <ul className='list-disc list-inside pl-4'>
              {listItems.map((item) => (
                <li key={item}>{item}</li>
              ))}
            </ul>
          )}
        </div>
        <span className='absolute inset-y-0 right-0 flex mt-3 mr-4'>
          <svg
            onClick={() => setIsOpen(false)}
            className={`w-6 h-6 p-1 fill-current rounded-full ${CLOSE_BTN_HOVER_CLASSES[type]}`}
            role='button'
            viewBox='0 0 20 20'
          >
            <path
              d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
              clipRule='evenodd'
              fillRule='evenodd'
            />
          </svg>
        </span>
      </div>
    </Transition>
  )
}
