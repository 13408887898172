import React, { useContext } from 'react'
import { Transition } from '@headlessui/react'

import { LanguageContext } from '../../../context/LanguageContext'
import { SideOverContext } from '../../../context/SideOverContext'
import { viewLocalizedText } from '../../../utils/viewLocalizedText'

export const SideOver: React.FC = (): React.ReactElement => {
  const { state, setState } = useContext(SideOverContext)
  const { isOpen, content } = state
  const { userLanguage } = useContext(LanguageContext)

  const localizedContent = viewLocalizedText({
    fallback: '',
    label: content,
    language: userLanguage
  })

  return (
    <Transition
      show={isOpen}
      className='fixed inset-0 overflow-hidden z-20'
      data-cy='side-over'
    >
      <div className='absolute inset-0 overflow-hidden'>
        <Transition.Child
          enter='ease-in-out duration-500'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in-out duration-500'
          leaveFrom='opacity-100'
          leaveTo='opacity-0 scale-95'
          className='absolute inset-0 bg-slate-800 bg-opacity-70 transition-opacity'
          aria-hidden='true'
        />
        <section
          className='absolute inset-y-0 right-0 pl-10 max-w-full flex'
          aria-labelledby='slide-over-heading'
        >
          <Transition.Child
            enter='transform transition ease-in-out duration-500 sm:duration-700'
            enterFrom='translate-x-full'
            enterTo='translate-x-0'
            leave='transform transition ease-in-out duration-500 sm:duration-700'
            leaveFrom='translate-x-0'
            leaveTo='translate-x-full'
            className='relative w-screen max-w-4xl'
          >
            <Transition.Child
              enter='ease-in-out duration-500'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in-out duration-500'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
              className='absolute top-0 left-0 -ml-8 pt-4 pr-2 flex sm:-ml-10 sm:pr-4'
            >
              <button
                type='button'
                data-cy='close-side-over'
                onClick={() => {
                  setState({ ...state, isOpen: false })
                }}
                className='rounded-md text-white hover:text-gray-20 focus:outline-none focus:ring-2 focus:ring-white'
              >
                <span className='sr-only'>Close panel</span>
                {/* Heroicon name: x */}
                <svg
                  className='h-6 w-6'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                  aria-hidden='true'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth={2}
                    d='M6 18L18 6M6 6l12 12'
                  />
                </svg>
              </button>
            </Transition.Child>

            <div className='h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll'>
              <div className='mt-6 relative flex-1 px-4 sm:px-6'>
                <div className='absolute inset-0 px-4 sm:px-6'>
                  <div
                    className='markdown pb-4'
                    aria-hidden='true'
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: localizedContent }}
                  />
                </div>
              </div>
            </div>
          </Transition.Child>
        </section>
      </div>
    </Transition>
  )
}
