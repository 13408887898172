import { defaultTo } from 'ramda'

import type { AvailableLanguages, LabelType } from '../types/localization.types'

export const viewLocalizedText = ({
  fallback,
  label,
  language
}: {
  fallback: string
  label?: LabelType
  language: AvailableLanguages
}): string => {
  const FALLBACK_LANGUAGE: AvailableLanguages = 'en'

  if (!label) {
    return fallback
  }

  const l = defaultTo(label[FALLBACK_LANGUAGE], label[language])

  if (l) return l

  return fallback
}
