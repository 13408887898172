/* eslint-disable no-nested-ternary */
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { isNil } from 'ramda'

import { LanguageContext } from '../../../context/LanguageContext'
import type { CalculationResultsType } from '../../../types/types'
import { viewLocalizedText } from '../../../utils/viewLocalizedText'
import { Badge } from '../../Badge/Badge'

interface Props {
  calculationResults?: CalculationResultsType[]
}

export const CalculationResultsTable: React.FC<Props> = ({
  calculationResults
}): React.ReactElement => {
  const { t } = useTranslation()
  const { userLanguage } = useContext(LanguageContext)

  return (
    <div className='overflow-x-auto -mx-6 sm:-mx-8'>
      <div className='py-2 align-middle inline-block min-w-full px-8 sm:px-8'>
        <div className='overflow-hidden border-b border-gray-40'>
          <table
            className='table-auto min-w-full'
            data-cy='calculation-results-table'
          >
            <thead className='bg-gray-100'>
              <tr>
                <th
                  scope='col'
                  className='px-8 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider'
                >
                  {t(
                    'pages.calculation_page.calculation_results_table.columns.result_id'
                  )}
                </th>
                <th
                  scope='col'
                  className='px-8 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider'
                >
                  {t(
                    'pages.calculation_page.calculation_results_table.columns.label'
                  )}
                </th>
                <th
                  scope='col'
                  className='px-8 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider'
                >
                  {t(
                    'pages.calculation_page.calculation_results_table.columns.result'
                  )}
                </th>
                <th
                  scope='col'
                  className='px-8 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider'
                >
                  {t(
                    'pages.calculation_page.calculation_results_table.columns.unit'
                  )}
                </th>
                <th
                  scope='col'
                  className='px-8 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider'
                >
                  {t(
                    'pages.calculation_page.calculation_results_table.columns.interpretation'
                  )}
                </th>
                <th
                  scope='col'
                  className='px-8 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider'
                >
                  {t(
                    'pages.calculation_page.calculation_results_table.columns.status'
                  )}
                </th>
              </tr>
            </thead>
            <tbody className='bg-white divide-y divide-gray-40'>
              {calculationResults &&
                calculationResults.map((result) => {
                  const unit = viewLocalizedText({
                    fallback: '',
                    label: result.unit,
                    language: userLanguage
                  })

                  const label = viewLocalizedText({
                    fallback: '',
                    label: result.label,
                    language: userLanguage
                  })

                  const interpretation = viewLocalizedText({
                    fallback: '',
                    label: result.interpretation,
                    language: userLanguage
                  })

                  const { status } = result

                  return (
                    <tr key={result.subresult_id}>
                      <td className='px-8 py-4'>
                        <div className='text-sm font-medium text-gray-900'>
                          <Badge
                            title={result.subresult_id}
                            color='grey'
                            uppercase={false}
                          />
                        </div>
                      </td>
                      <td className='px-8 py-4'>
                        <div className='text-sm font-medium text-gray-900'>
                          {label}
                        </div>
                      </td>
                      <td className='px-8 py-4'>
                        <div className='text-sm font-medium text-gray-900'>
                          {!isNil(result.result)
                            ? result.result.toString()
                            : ''}
                        </div>
                      </td>
                      <td className='px-8 py-4'>
                        <div className='text-sm font-medium text-gray-900'>
                          {unit}
                        </div>
                      </td>
                      <td className='px-8 py-4'>
                        <div className='text-sm font-medium text-gray-900'>
                          {interpretation}
                        </div>
                      </td>
                      <td className='px-8 py-4'>
                        <div className='text-sm font-medium text-gray-900'>
                          {status === 'CALCULATED' ? (
                            <Badge title={status} color='green' uppercase />
                          ) : status === 'NOT APPLICABLE' ? (
                            <Badge title={status} color='indigo' uppercase />
                          ) : status === 'MISSING' ? (
                            <Badge title={status} color='red' uppercase />
                          ) : (
                            { status }
                          )}
                        </div>
                      </td>
                    </tr>
                  )
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
