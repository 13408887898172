import React from 'react'
import moment from 'moment'

import packageDetails from '../../../../package.json'
import { Container } from '../Container/Container'

const { version } = packageDetails
export default {}

export const Footer: React.FC = (): React.ReactElement => {
  const linkedInLogoUrl = '/images/linked-in-logo.svg'
  return (
    <footer className='relative bg-white pt-8 pb-6 border-gray-30 border-t'>
      <Container>
        <div className='flex flex-wrap'>
          <div className='w-full md:w-6/12'>
            <h4 className='text-3xl font-semibold text-gray-800'>Awell</h4>
            <h5 className='text-lg mt-0 mb-2 text-gray-600'>
              The faster path to great care.
            </h5>
            <div className='mt-6'>
              <ul className='flex items-center'>
                <li>
                  <a
                    href='https://be.linkedin.com/company/awell'
                    target='_blank'
                    rel='noreferrer'
                    title='Awell LinkedIn'
                  >
                    <img
                      src={linkedInLogoUrl}
                      alt='Awell LinkedIn'
                      className='w-5'
                    />
                  </a>
                </li>
              </ul>
            </div>
            <p className='text-sm mt-6 text-gray-600 font-semibold'>
              {`Currently v${version}`}
            </p>
          </div>
          <div className='w-full md:w-6/12'>
            <div className='flex flex-wrap items-top'>
              <div className='w-full md:w-6/12 xl:w-4/12 pt-6 md:pt-0 md:px-4 ml-auto'>
                <ul className='list-unstyled'>
                  <li>
                    <a
                      className='text-gray-700 hover:text-blue-700 hover:underline block pb-2 text-sm'
                      href='https://www.awell.health'
                      target='_blank'
                      rel='noreferrer'
                      title='Awell'
                    >
                      Awell
                    </a>
                  </li>
                  <li>
                    <a
                      className='text-gray-700 hover:text-blue-700 hover:underline block pb-2 text-sm'
                      href='https://help.awellhealth.com/en/'
                      target='_blank'
                      rel='noreferrer'
                      title='Help Center'
                    >
                      Help Center
                    </a>
                  </li>
                  <li>
                    <a
                      className='text-gray-700 hover:text-blue-700 hover:underline block pb-2 text-sm'
                      href='https://developers.awellhealth.com/faq'
                      target='_blank'
                      rel='noreferrer'
                      title='FAQ'
                    >
                      FAQ
                    </a>
                  </li>
                  <li>
                    <a
                      className='text-gray-700 hover:text-blue-700 hover:underline block pb-2 text-sm'
                      href='https://awellhealth.com/contact/'
                      target='_blank'
                      rel='noreferrer'
                      title='contant'
                    >
                      Contact
                    </a>
                  </li>
                </ul>
              </div>
              <div className='w-full md:w-6/12 xl:w-4/12 pt-6 md:pt-0 md:px-4'>
                <ul className='list-unstyled'>
                  <li>
                    <a
                      className='text-gray-700 hover:text-blue-700 hover:underline block pb-2 text-sm'
                      href='https://www.awellhealth.com/privacy-policy'
                      title='Privacy Policy'
                      target='_blank'
                      rel='noreferrer'
                    >
                      Privacy Policy
                    </a>
                  </li>
                  <li>
                    <a
                      className='text-gray-700 hover:text-blue-700 hover:underline block pb-2 text-sm'
                      href='https://www.awellhealth.com/terms-conditions'
                      title='Terms and Conditions'
                      target='_blank'
                      rel='noreferrer'
                    >
                      Terms and Conditions
                    </a>
                  </li>
                  <li>
                    <a
                      className='text-gray-700 hover:text-blue-700 hover:underline block pb-2 text-sm'
                      href='https://www.awellhealth.com/cookie-policy'
                      target='_blank'
                      rel='noreferrer'
                      title='Cookie Policy'
                    >
                      Cookie Policy
                    </a>
                  </li>
                  <li>
                    <a
                      className='text-gray-700 hover:text-blue-700 hover:underline block pb-2 text-sm'
                      href='https://www.awellhealth.com/hipaa-at-awell'
                      target='_blank'
                      title='HIPAA At Awell'
                      rel='noreferrer'
                    >
                      HIPAA At Awell
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <hr className='my-6 border-gray-200' />
        <div className='flex flex-wrap items-center md:justify-between justify-center'>
          <div className='w-full md:w-4/12 px-4 mx-auto text-center'>
            <div className='text-sm text-gray-700 font-normal py-1'>
              Copyright © {moment().year()} - Awell Score Browser
              <br />
              <span className='font-semibold'>
                Made with <span className='text-red-600'>&hearts;</span> by{' '}
                <a
                  href='https://www.awell.health'
                  className='text-blue-700 hover:text-blue-500'
                  target='_blank'
                  rel='noreferrer'
                >
                  Awell
                </a>
              </span>
            </div>
          </div>
        </div>
      </Container>
    </footer>
  )
}
