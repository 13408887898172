import React from 'react'

import { useClipboard } from '../../../hooks/useClipboard'

interface CopyButtonProps {
  contentToCopy: string
  copyText?: string
  copiedText?: string
  failedText?: string
}

const CopiedIcon = () => (
  <svg
    className='w-3 h-3'
    stroke='currentColor'
    fill='none'
    strokeWidth='2'
    viewBox='0 0 24 24'
    strokeLinecap='round'
    strokeLinejoin='round'
    aria-hidden='true'
    focusable='false'
    height='1em'
    width='1em'
    xmlns='http://www.w3.org/2000/svg'
  >
    <polyline points='20 6 9 17 4 12' />
  </svg>
)

const CopyIcon = () => (
  <svg
    className='w-3 h-3'
    stroke='currentColor'
    fill='none'
    strokeWidth='2'
    viewBox='0 0 24 24'
    strokeLinecap='round'
    strokeLinejoin='round'
    aria-hidden='true'
    focusable='false'
    height='1em'
    width='1em'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2' />
    <rect x='8' y='2' width='8' height='4' rx='1' ry='1' />
  </svg>
)

export const CopyButton = ({
  contentToCopy,
  copyText = 'Copy',
  copiedText = 'Copied',
  failedText = 'Copy failed'
}: CopyButtonProps): React.ReactElement => {
  const { copyFn, copyStatus } = useClipboard()

  let buttonText = copyText

  if (copyStatus === 'copied') {
    buttonText = copiedText
  } else if (copyStatus === 'failed') {
    buttonText = failedText
  }

  return (
    <button
      type='button'
      onClick={() => copyFn(contentToCopy)}
      className='flex items-center px-4 py-2 mr-3 text-xs font-medium text-gray-900 bg-white border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-offset-2 focus:ring-2 focus:ring-gray-300'
      data-track-heap='copy-code'
    >
      <span className='mr-1'>
        {copyStatus === 'copied' ? <CopiedIcon /> : <CopyIcon />}
      </span>
      {buttonText}
    </button>
  )
}
