import React, { ReactNode } from 'react'

interface ContainerProps {
  children: ReactNode
  className?: string
}

export const Container: React.FC<ContainerProps> = ({
  children,
  className = ''
}): React.ReactElement => {
  // return <div className={`px-4 sm:px-6 lg:px-8 ${className}`}>{children}</div>
  return <div className={`max-w-7xl mx-auto ${className}`}>{children}</div>
}
