import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'

import { Button } from '../../components/Button/Button'
import { CalculationExecution } from '../../components/CalculationExecution/CalculationExecution'
import { CalculationResultsTable } from '../../components/CalculationExecution/CalculationResultsTable/CalculationResultsTable'
import { PageLayout } from '../../components/Layout/PageLayout'
import { LoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner'
import { Panel } from '../../components/Panel/Panel'
import { LanguageContext } from '../../context/LanguageContext'
import {
  executeCalculation,
  getCalculation,
  simulateCalculation
} from '../../services/calculation.service'
import type {
  ApiErrorType,
  CalculationInputType,
  CalculationResultsType,
  CalculationType
} from '../../types/types'
import { viewLocalizedText } from '../../utils/viewLocalizedText'

export const CalculationPage: React.FC = (): React.ReactElement => {
  const { t } = useTranslation()
  const { calculationId } = useParams<{ calculationId: string }>()
  const { userLanguage } = useContext(LanguageContext)
  const history = useHistory()

  const [isLoading, setIsLoading] = useState(true)
  const [calculation, setCalculation] = useState<CalculationType>()
  const [calculationInputData, setCalculationInputData] =
    useState<CalculationInputType>({})
  const [isCalculating, setIsCaclulating] = useState(false)
  const [isSimulating, setIsSimulating] = useState(false)
  const [error, setError] = useState<ApiErrorType>({})
  const [calculationResults, setCalculationResults] =
    useState<CalculationResultsType[]>()

  const handleCalculationExecution = () => {
    setIsCaclulating(true)
    executeCalculation(calculationId, calculationInputData)
      .then((res) => {
        setError({})
        setIsCaclulating(false)
        setCalculationResults(res)
        return true
      })
      .catch((e) => {
        const { errorData } = e
        setCalculationResults([])
        setIsCaclulating(false)
        setError(errorData)
      })
  }

  const handleCalculationSimulation = () => {
    setIsSimulating(true)
    simulateCalculation(calculationId)
      .then((res) => {
        setError({})
        setIsSimulating(false)
        setCalculationResults(res.simulated_calculation_results)
        setCalculationInputData(res.simulated_calculation_input)
        return true
      })
      .catch((e) => {
        const { errorData } = e
        // eslint-disable-next-line no-console
        console.log(errorData)
      })
  }

  const handleDataInput = (input_id: string) => (newValue: string | number) =>
    setCalculationInputData({
      ...calculationInputData,
      [input_id]: newValue
    })

  useEffect(() => {
    let isMounted = true

    getCalculation(calculationId)
      .then((res) => {
        if (isMounted) {
          setCalculation(res)
          setIsLoading(false)
        }

        return null
      })
      // eslint-disable-next-line no-console
      .catch((e) => console.log(e))
    return () => {
      isMounted = false
    }
  }, [calculationId])

  if (isLoading)
    return (
      <PageLayout breadcrumbs={[{ text: 'Scores', url: '/' }]}>
        <div className='bg-white shadow overflow-hidden rounded-lg border border-gray-200'>
          <div className='flex justify-center items-center h-64'>
            <LoadingSpinner size='md' />
          </div>
        </div>
      </PageLayout>
    )

  const breadcrumbs = [
    {
      url: '/',
      text: 'Scores'
    },
    {
      url: '',
      text: viewLocalizedText({
        fallback: '',
        label: calculation?.calculation_name,
        language: userLanguage
      })
    }
  ]

  return (
    <PageLayout breadcrumbs={breadcrumbs}>
      <div className='lg:flex lg:items-center lg:justify-between pb-10'>
        <div className='flex-1 min-w-0 mr-6'>
          <h1 className='text-3xl text-gray-900 font-semibold'>
            {viewLocalizedText({
              fallback: '',
              label: calculation?.calculation_name,
              language: userLanguage
            })}
          </h1>
        </div>
        <div className='mt-3 lg:mt-0'>
          <div className='flex'>
            <div>
              <Button
                label={t('buttons.view_documentation')}
                onClick={() =>
                  history.push(`/calculations/${calculationId}/documentation`)
                }
              />
            </div>
            <div className='ml-4' data-cy='simulate-calculation'>
              <Button
                label={t('buttons.simulate')}
                isLoading={isSimulating}
                onClick={() => handleCalculationSimulation()}
                color='blue'
              />
            </div>
          </div>
        </div>
      </div>
      <div className='flex'>
        <div className='w-full'>
          <Panel
            title='Inputs'
            subtitle='Answer the questions below and press "Calculate" to get the results'
          >
            <CalculationExecution
              error={error}
              calculationInputData={calculationInputData}
              onDataInput={handleDataInput}
              calculationBlueprint={calculation?.calculation_blueprint}
            />
            <div className='flex px-6 sm:px-8 mb-8'>
              <Button
                label={t('buttons.execute')}
                type='submit'
                fluid
                isLoading={isCalculating}
                onClick={() => handleCalculationExecution()}
                color='blue'
                size='normal'
              />
            </div>
            <CalculationResultsTable calculationResults={calculationResults} />
          </Panel>
        </div>
      </div>
    </PageLayout>
  )
}
