/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'

import file from '../../../package.json'
import { PageLayout } from '../../components/Layout/PageLayout'
import { Panel } from '../../components/Panel/Panel'

const { version } = file
const manufacturerLogo = 'images/manufacturer.png'
const ceLogo = 'images/ce-logo-transparent.png'
const instructionsLogo = 'images/info-black.gif'

const classNames = {
  informationRow: 'flex bg-white px-4 py-5 sm:px-6',
  alternatingRowColor: 'bg-gray-50',
  textLeftColumn: 'text-sm sm:text-base font-medium text-gray-500',
  textRightColumn:
    'ml-4 sm:ml-20 text-sm sm:text-base text-gray-800 sm:mt-0 sm:col-span-2'
}

export const AboutPage: React.FC = (): React.ReactElement => {
  const breadcrumbs = [
    {
      url: '/about',
      text: 'About'
    }
  ]

  return (
    <PageLayout breadcrumbs={breadcrumbs}>
      <Panel
        title='Awell Score'
        subtitle="More information on Awell's compliance with Medical Device Regulation."
      >
        <div className='content-table'>
          <dl>
            <div
              className={`${classNames.informationRow} ${classNames.alternatingRowColor}`}
            >
              <dt
                className={classNames.textLeftColumn}
                style={{ minWidth: '100px' }}
              >
                <img
                  src={instructionsLogo}
                  width='52'
                  alt='Instructions and Help center'
                />
              </dt>
              <dd
                className={classNames.textRightColumn}
                data-cy='information-and-help-section'
              >
                Read the quick start guide and the FAQ at{' '}
                <a
                  href='https://help.awellhealth.com/'
                  target='_blank'
                  rel='noopener noreferrer'
                  title='help.awellhealth.com'
                  className='text-blue-700 underline'
                >
                  help.awellhealth.com
                </a>
              </dd>
            </div>
            <div className={classNames.informationRow}>
              <dt
                className={classNames.textLeftColumn}
                style={{ minWidth: '100px' }}
              >
                <img
                  src={manufacturerLogo}
                  width='52'
                  alt='Manufacturer - Awell Health'
                />
              </dt>
              <dd
                className={classNames.textRightColumn}
                data-cy='manufacturer-section'
              >
                Manufacturer:
                <br />
                <b>Awell Health</b>
                <br />
                Alsembergsesteenweg 837
                <br />
                1180 Brussels
                <br />
                Belgium
                <br />
                BE 0696.819.789
                <br />
                <br />
                <b>Contact information</b>
                <br />
                <a
                  href='mailto:info@awellhealth.com'
                  target='_blank'
                  rel='noopener noreferrer'
                  title='info@awellhealth.com'
                >
                  info@awellhealth.com
                </a>
              </dd>
            </div>
            <div
              className={`${classNames.informationRow} ${classNames.alternatingRowColor}`}
            >
              <dt
                className={classNames.textLeftColumn}
                style={{ minWidth: '100px' }}
              >
                <img
                  src={ceLogo}
                  width='52'
                  alt={`Awell Calculated Score Suite V${version}`}
                />
              </dt>
              <dd
                className={classNames.textRightColumn}
                data-cy='device-section'
              >
                Device:
                <br />
                <b>{`Awell Calculated Score Suite V${version}`}</b>
                <br />
                <br />
                Year of CE mark: <b>2021</b>
              </dd>
            </div>
            <div className={classNames.informationRow}>
              <dt
                className={classNames.textLeftColumn}
                style={{ minWidth: '100px' }}
              >
                Date of issue
              </dt>
              <dd className={classNames.textRightColumn}>March 2021</dd>
            </div>
          </dl>
        </div>
      </Panel>
    </PageLayout>
  )
}
