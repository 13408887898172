import React from 'react'
import { NavLink } from 'react-router-dom'
import { Transition } from '@headlessui/react'

import type { MenuItem } from '../../../../../types/types'

const SingleMenuItem: React.FC<{ menuItem: MenuItem }> = ({ menuItem }) => {
  const { label, path, exact } = menuItem

  const defaultClasses =
    'block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:bg-gray-900 hover:text-white'
  const activeClasses = `bg-gray-900 text-white`

  return (
    <NavLink
      exact={exact}
      to={path}
      className={defaultClasses}
      activeClassName={activeClasses}
      activeStyle={{ color: 'white' }} // This is needed to overwrite the default text-gray-600 class
    >
      {label}
    </NavLink>
  )
}

interface Props {
  menuItems: MenuItem[]
  menuIsOpen: boolean
}

export const MobileMenu: React.FC<Props> = ({
  menuItems,
  menuIsOpen
}): React.ReactElement => {
  return (
    <Transition
      show={menuIsOpen}
      enter='duration-250 ease-out'
      enterFrom='opacity-0 scale-95'
      enterTo='opacity-100 scale-100'
      leave='duration-100 ease-in'
      leaveFrom='opacity-100 scale-100'
      leaveTo='opacity-0 scale-95'
      className='bg-gray-700 transition transform origin-top-left sm:hidden'
    >
      <div className='px-2 pt-2 pb-3 space-y-1'>
        {menuItems.map((menuItem) => (
          <SingleMenuItem menuItem={menuItem} key={menuItem.path} />
        ))}
      </div>
    </Transition>
  )
}
