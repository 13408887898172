import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Transition } from '@headlessui/react'

import { AVAILABLE_LANGUAGES } from '../../../../config/languages'
import { LanguageContext } from '../../../../context/LanguageContext'
import { LanguageIcons } from './LanguageIcons'

export const SelectLanguage: React.FC = (): React.ReactElement => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const { userLanguage, userLanguageChange } = useContext(LanguageContext)
  const { t } = useTranslation()

  return (
    <div className='relative inline-block text-left'>
      <button
        type='button'
        className='inline-flex justify-center items-center w-full rounded-md border border-gray-300 shadow-sm px-2 sm:px-4 py-2 sm:py-3 bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-600'
        id='options-menu'
        onClick={() => setIsOpen(!isOpen)}
        aria-haspopup='true'
        aria-expanded='true'
        data-cy='select-language-button'
      >
        <div className='sm:pr-2'>{LanguageIcons[userLanguage]}</div>
        <span className='text-sm hidden sm:block'>
          {t(`languages.${userLanguage}`)}
        </span>
        {/* Heroicon name: solid/chevron-down */}
        <svg
          className='-mr-1 ml-1 sm:ml-2 h-5 4-4'
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 20 20'
          fill='currentColor'
          aria-hidden='true'
        >
          <path
            fillRule='evenodd'
            d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z'
            clipRule='evenodd'
          />
        </svg>
      </button>
      <Transition
        show={isOpen}
        enter='transition ease-out duration-200 transform'
        enterFrom='opacity-0 scale-95'
        enterTo='opacity-100 scale-100'
        leave='transition ease-in duration-100 transform'
        leaveFrom='opacity-100 scale-100'
        leaveTo='opacity-0 scale-95'
        className='z-10 origin-top-right bg-white absolute right-0 mt-2 rounded-md shadow-lg ring-1 ring-black ring-opacity-5'
      >
        <div
          className='py-1'
          role='menu'
          aria-orientation='vertical'
          aria-labelledby='options-menu'
          data-cy='list-of-available-languages'
        >
          {AVAILABLE_LANGUAGES.map((language) => (
            <button
              type='button'
              className='flex items-center w-full text-left block px-4 py-2 pr-12 text-gray-700 hover:bg-gray-100 hover:text-gray-700'
              key={language}
              onClick={() => {
                userLanguageChange(language)
                setIsOpen(false)
              }}
              data-cy={`select-language-${language}`}
            >
              <span className='pr-3'>{LanguageIcons[language]}</span>
              {t(`languages.${language}`)}
            </button>
          ))}
        </div>
      </Transition>
    </div>
  )
}
