import 'rc-slider/assets/index.css'

import React from 'react'
import Slider from 'rc-slider'

import type { NumberInputType } from '../../../../types/calculation-inputs.types'
import type { AvailableLanguages } from '../../../../types/localization.types'
import { viewLocalizedText } from '../../../../utils/viewLocalizedText'
import { handleStyle, railStyle, trackStyle } from './styles'

interface Props {
  value: string | number
  hasError: boolean
  language: AvailableLanguages
  format?: string
  inputType: NumberInputType
  onChange: (newValue: string | number) => void
}

export const NumericSliderInput: React.FC<Props> = ({
  // hasError,
  value,
  language,
  inputType,
  onChange
}): React.ReactElement => {
  const { range } = inputType

  const onSliderChange = (newSliderValue: number) => {
    onChange(newSliderValue)
  }

  return (
    <div className='rounded-md mt-6'>
      <Slider
        min={range?.min?.value}
        max={range?.max?.value}
        value={Number(value)}
        trackStyle={trackStyle}
        onChange={onSliderChange}
        handleStyle={handleStyle}
        railStyle={railStyle}
      />
      <div className='flex justify-between mt-4 text-sm'>
        <div className='max-w-xs text-left'>
          <span>{range?.min?.value}</span>
          <br />
          {viewLocalizedText({
            fallback: '',
            label: range?.min?.label,
            language
          })}
        </div>
        <div className='max-w-xs text-right'>
          <span>{range?.max?.value}</span>
          <br />
          {viewLocalizedText({
            fallback: '',
            label: range?.max?.label,
            language
          })}
        </div>
      </div>
    </div>
  )
}
