/* eslint-disable simple-import-sort/imports */
import axios from 'axios'

import { ApiError } from '../api/errors/ApiError'
import { api } from '../config/api'
import type {
  CalculationResultsType,
  CalculationType,
  CalculationsLibraryType,
  CsvUploadedResponseType,
  SimulatedCalculationResultsType
} from '../types/types'

export const getAllCalculations = (): Promise<CalculationsLibraryType> =>
  axios
    .get(`${api.url}${api.endpoints.GET_ALL_CALCULATIONS()}`)
    .then((res) => res.data)

export const getCalculation = (
  calculationId: string
): Promise<CalculationType> =>
  axios
    .get(`${api.url}${api.endpoints.GET_SINGLE_CALCULATION(calculationId)}`)
    .then((res) => res.data)

export const simulateCalculation = (
  calculationId: string
): Promise<SimulatedCalculationResultsType> =>
  axios
    .get(`${api.url}${api.endpoints.SIMULATE_CALCULATION(calculationId)}`)
    .then((res) => res.data)

export const executeCalculation = (
  calculationId: string,
  calculationInput: Record<string, unknown>
): Promise<CalculationResultsType[]> =>
  axios
    .post(`${api.url}${api.endpoints.EXECUTE_CALCULATION()}`, {
      calculation_id: calculationId,
      calculation_input: calculationInput
    })
    .then((res) => res.data)
    .catch((err) => {
      if (err.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        throw new ApiError(err.response.data.error)
      }

      if (err.request) {
        // eslint-disable-next-line no-console
        console.log(err.request)
      }

      // Something happened in setting up the request that triggered an Error
      throw new ApiError({ message: err.message })
    })

export const uploadCsv = (
  calculationId: string,
  csvData: FormData
): Promise<CsvUploadedResponseType> =>
  axios
    .post(
      `${api.url}${api.endpoints.UPLOAD_CSV_FOR_CALCULATION(calculationId)}`,
      csvData
    )
    .then((res) => res.data)
    .catch((err) => {
      if (err.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        throw new ApiError(err.response.data.error)
      }

      if (err.request) {
        // eslint-disable-next-line no-console
        console.log(err.request)
      }

      // Something happened in setting up the request that triggered an Error
      throw new ApiError({ message: err.message })
    })
