import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import {
  AboutPage,
  CalculationCsvPage,
  CalculationPage,
  CalculationsPage,
  DocumentationPage
} from './pages'
import { routes } from './shared/constants/routes'

const App: React.FC = (): React.ReactElement => {
  return (
    <BrowserRouter>
      {/* A <Switch> looks through its children <Route>s and
        renders the first one that matches the current URL. */}
      <Switch>
        <Route path={routes.UPLOAD_CSV.path}>
          <CalculationCsvPage />
        </Route>
        <Route path={routes.VIEW_DOCUMENTATION.path}>
          <DocumentationPage />
        </Route>
        <Route path={routes.VIEW_CALCULATION.path}>
          <CalculationPage />
        </Route>
        <Route exact path={routes.CALCULATIONS.path}>
          <CalculationsPage />
        </Route>
        <Route exact path={routes.ABOUT.path}>
          <AboutPage />
        </Route>
        <Route path={routes.HOME.path}>
          <CalculationsPage />
        </Route>
      </Switch>
    </BrowserRouter>
  )
}

export default App
