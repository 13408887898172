import React from 'react'

interface Props {
  value: string
  hasError: boolean
  format?: string
  onChange: (newValue: string) => void
}

export const TextInput: React.FC<Props> = ({
  format = null,
  hasError,
  value,
  onChange
}): React.ReactElement => {
  return (
    <div className='flex shadow-sm rounded-md'>
      <input
        className={`${
          format ? 'rounded-l-md' : 'rounded-md'
        } relative flex-1 w-full bg-white border border-gray-300 pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-blue-600 focus:border-blue-600 ${
          hasError ? 'border-red-700' : ''
        }`}
        type='text'
        onChange={(e) => onChange(e.target.value.toString())}
        value={value}
        data-cy={`${hasError ? 'input-has-error' : ''}`}
      />
      {format && (
        <span className='w-36 inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-100 text-gray-500 text-sm'>
          {format}
        </span>
      )}
    </div>
  )
}
