type ErrorData = {
  name: string
  message: string
  invalid_inputs?: Record<string, unknown>
}

export class ApiError extends Error {
  public errorData: ErrorData

  constructor(errorData: ErrorData, ...args: string[]) {
    super(...args)
    this.errorData = {
      name: errorData.name,
      message: errorData.message,
      invalid_inputs: errorData.invalid_inputs
    }
  }
}
