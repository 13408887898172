import React, { useState } from 'react'

type BreadcrumbContextStateType = {
  to: string
  text: string
}[]

export interface BreadcrumbContextState {
  breadcrumbs: BreadcrumbContextStateType
  setBreadcrumbs: (newBreadcrumbs: BreadcrumbContextStateType) => void
}

const contextDefaultValues: BreadcrumbContextState = {
  breadcrumbs: [
    {
      to: '/',
      text: 'Scores'
    }
  ],
  setBreadcrumbs: () => {}
}

const BreadcrumbsContext =
  React.createContext<BreadcrumbContextState>(contextDefaultValues)

const BreadcrumbsProvider: React.FC = ({ children }): React.ReactElement => {
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbContextStateType>(
    contextDefaultValues.breadcrumbs
  )

  return (
    <BreadcrumbsContext.Provider value={{ breadcrumbs, setBreadcrumbs }}>
      {children}
    </BreadcrumbsContext.Provider>
  )
}

export { BreadcrumbsContext, BreadcrumbsProvider }
