// eslint-disable-next-line import/no-unassigned-import
import './i18n'

import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'

import App from './App'
import { LoadingSpinner } from './components/LoadingSpinner/LoadingSpinner'
import { BreadcrumbsProvider } from './context/BreadcrumbContext'
import { LanguageProvider } from './context/LanguageContext'
import { SideOverProvider } from './context/SideOverContext'

const LoadingComponent = (
  <div className='flex flex-1 items-center justify-center h-screen'>
    <LoadingSpinner size='lg' />
  </div>
)

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={LoadingComponent}>
      <BreadcrumbsProvider>
        <LanguageProvider>
          <SideOverProvider>
            <App />
          </SideOverProvider>
        </LanguageProvider>
      </BreadcrumbsProvider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
)
