/* eslint-disable jsx-a11y/label-has-associated-control */
// @ts-nocheck
import React, { useContext } from 'react'
import { isEmpty } from 'ramda'

import { LanguageContext } from '../../../context/LanguageContext'
import type { CalculationParameterInputType } from '../../../types/calculation-inputs.types'
import { LabelType } from '../../../types/localization.types'
import type { InvalidInputType } from '../../../types/types'
import { viewLocalizedText } from '../../../utils/viewLocalizedText'
import { Badge } from '../../Badge/Badge'
import { BooleanInput } from './BooleanInput/BooleanInput'
import { DateInput } from './DateInput/DateInput'
import { MultipleSelect } from './MultipleSelect/MultipleSelect'
import { NumericInput } from './NumericInput/NumericInput'
import { NumericSliderInput } from './NumericSliderInput/NumericSliderInput'
import { SingleSelect } from './SingleSelect/SingleSelect'
import { TextInput } from './TextInput/TextInput'

interface Props {
  inputId: string
  label?: LabelType
  placeholder?: string
  value?: string | number | boolean | string[] | number[]
  inputError?: InvalidInputType
  format?: string
  info?: LabelType
  inputType: CalculationParameterInputType
  onChange: (
    newValue: string | number | boolean | Date | string[] | number[]
  ) => void
}

export const Input: React.FC<Props> = ({
  inputId,
  label,
  value = '',
  inputError = {},
  format = '',
  info = undefined,
  inputType,
  onChange
}): React.ReactElement => {
  const hasError = !isEmpty(inputError)
  const { userLanguage } = useContext(LanguageContext)
  const {
    type,
    // @ts-expect-error
    allowed_answers: allowedAnswers,
    // @ts-expect-error
    component = undefined
  } = inputType

  const labelText = viewLocalizedText({
    fallback: '',
    label,
    language: userLanguage
  })

  return (
    <div className='py-6 border-b border-gray-200' key={inputId}>
      <div className='relative w-full' data-cy={`input-${inputId}`}>
        <div className='mb-2'>
          <Badge title={inputId} color='white' uppercase={false} />
        </div>
        {labelText === '' ? (
          ''
        ) : (
          <label
            className={`block font-medium text-gray-900 mb-2 ${
              hasError ? 'text-red-700' : ''
            }`}
          >
            {labelText}
          </label>
        )}

        {type === 'boolean' && (
          <BooleanInput
            inputId={inputId}
            language={userLanguage}
            hasError={hasError}
            onChange={onChange}
            // @ts-expect-error
            value={value}
            format={format}
            options={
              allowedAnswers || [
                { value: false, label: { en: 'No' } },
                { value: true, label: { en: 'Yes' } }
              ]
            }
          />
        )}

        {type === 'number' && !allowedAnswers && !component && (
          <NumericInput
            hasError={hasError}
            onChange={onChange}
            // @ts-expect-error
            value={value}
            format={format}
            // @ts-expect-error
            inputType={inputType}
          />
        )}

        {type === 'number' && allowedAnswers && !component && (
          <SingleSelect
            inputId={inputId}
            language={userLanguage}
            hasError={hasError}
            onChange={onChange}
            // @ts-expect-error
            value={value}
            format={format}
            type='number'
            options={allowedAnswers}
          />
        )}

        {type === 'number' && component === 'slider' && (
          <NumericSliderInput
            hasError={hasError}
            onChange={onChange}
            language={userLanguage}
            // @ts-expect-error
            value={value}
            format={format}
            // @ts-expect-error
            inputType={inputType}
          />
        )}

        {type === 'string' && !allowedAnswers && (
          <TextInput
            hasError={hasError}
            onChange={onChange}
            // @ts-expect-error
            value={value}
            format={format}
          />
        )}

        {type === 'string' && allowedAnswers && (
          <SingleSelect
            inputId={inputId}
            language={userLanguage}
            hasError={hasError}
            onChange={onChange}
            // @ts-expect-error
            value={value}
            type='string'
            format={format}
            options={allowedAnswers}
          />
        )}

        {type === 'date' && (
          <DateInput
            hasError={hasError}
            onChange={onChange}
            // @ts-expect-error
            value={value}
            format={format}
          />
        )}

        {type === 'strings_array' && (
          <MultipleSelect
            inputId={inputId}
            language={userLanguage}
            hasError={hasError}
            onChange={onChange}
            // @ts-expect-error
            value={value}
            type='string'
            format={format}
            options={allowedAnswers}
          />
        )}

        {type === 'numbers_array' && (
          <MultipleSelect
            inputId={inputId}
            language={userLanguage}
            hasError={hasError}
            onChange={onChange}
            // @ts-expect-error
            value={value}
            type='number'
            format={format}
            options={allowedAnswers}
          />
        )}

        {info && (
          <div className='mt-1 text-sm text-gray-500'>
            {viewLocalizedText({
              fallback: '',
              label: info,
              language: userLanguage
            })}
          </div>
        )}
        {hasError && (
          <div className='mt-1'>
            <p className='text-red-700 text-sm'>
              {viewLocalizedText({
                fallback: '',
                label: inputError?.reason,
                language: userLanguage
              })}
            </p>
          </div>
        )}
      </div>
    </div>
  )
}
