import React from 'react'

import type {
  AvailableLanguages,
  LabelType
} from '../../../../types/localization.types'

interface Props {
  inputId: string
  language: AvailableLanguages
  placeholder?: string
  value: boolean
  hasError: boolean
  format: string
  options: Array<{
    value: boolean
    label?: LabelType
  }>
  onChange: (newValue: string | number | boolean) => void
}

export const BooleanInput: React.FC<Props> = ({
  // inputId,
  // language,
  value,
  format,
  // options,
  onChange
}): React.ReactElement => {
  const toggleBoolean = () => {
    return onChange(!value)
  }

  const handleKeyPress = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === 'Enter') {
      toggleBoolean()
    }
  }

  return (
    <div className='flex'>
      <div
        className='flex justify-between items-center focus:ring-blue-600'
        role='button'
        onClick={() => toggleBoolean()}
        onKeyPress={handleKeyPress}
        tabIndex={0}
      >
        <div
          className={`w-12 h-8 flex items-center bg-gray-300 rounded-full p-1 duration-300 ease-in-out ${
            value ? 'bg-blue-600' : ''
          }`}
        >
          <div
            className={`bg-white w-6 h-6 rounded-full shadow-md transform duration-300 ease-in-out ${
              value ? 'translate-x-4' : ''
            }`}
          />
        </div>
      </div>
      {format && (
        <span className='w-36 inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-200 bg-gray-50 text-gray-500 text-sm'>
          {format}
        </span>
      )}
    </div>
  )
}
