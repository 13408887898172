// @ts-nocheck
import React from 'react'
import { useTranslation } from 'react-i18next'

import type { AvailableLanguages } from '../types/localization.types'

export interface LanguageContextState {
  userLanguage: AvailableLanguages
  userLanguageChange: (newLanguage: AvailableLanguages) => void
}

const contextDefaultValues: LanguageContextState = {
  userLanguage: 'en',
  userLanguageChange: () => {}
}

const LanguageContext =
  React.createContext<LanguageContextState>(contextDefaultValues)

const LanguageProvider: React.FC = ({ children }): React.ReactElement => {
  const { i18n } = useTranslation()

  const provider = {
    userLanguage: i18n.language,
    userLanguageChange: (newLanguage: AvailableLanguages) => {
      i18n.changeLanguage(newLanguage)
      window.localStorage.setItem('i18nextLng', newLanguage)
    }
  }

  return (
    <LanguageContext.Provider value={provider}>
      {children}
    </LanguageContext.Provider>
  )
}

export { LanguageContext, LanguageProvider }
