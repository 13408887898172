import React from 'react'
import { NavLink } from 'react-router-dom'

import type { MenuItem } from '../../../../../types/types'

const SingleMenuItem: React.FC<{ menuItem: MenuItem }> = ({ menuItem }) => {
  const { label, path, exact, cypressSelector } = menuItem

  const defaultClasses =
    'text-gray-800 hover:bg-gray-200 hover:text-gray-900 px-4 py-2 rounded-md font-medium'
  const activeClasses = `bg-gray-200 text-gray-900`

  return (
    <NavLink
      exact={exact}
      to={path}
      className={defaultClasses}
      activeClassName={activeClasses}
    >
      <span data-cy={cypressSelector}>{label}</span>
    </NavLink>
  )
}

interface Props {
  menuItems: MenuItem[]
}

export const DesktopMenu: React.FC<Props> = ({
  menuItems
}): React.ReactElement => {
  return (
    <div className='hidden sm:block sm:ml-8'>
      <div className='flex space-x-6'>
        {menuItems.map((menuItem) => (
          <SingleMenuItem menuItem={menuItem} key={menuItem.path} />
        ))}
      </div>
    </div>
  )
}
