/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { CalculationsTable } from '../../components/CalculationsTable/CalculationsTable'
import { PageLayout } from '../../components/Layout/PageLayout'
import { LoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner'
import { Panel } from '../../components/Panel/Panel'
import { getAllCalculations } from '../../services/calculation.service'
import type { CalculationsLibraryType } from '../../types/types'

export const CalculationsPage: React.FC = (): React.ReactElement => {
  const [isLoading, setIsLoading] = useState(true)
  const { t } = useTranslation()

  const breadcrumbs = [
    {
      url: '',
      text: 'Scores'
    }
  ]

  const [filter, setFilter] = useState<{
    calculationNameSearchString: string
  }>({ calculationNameSearchString: '' })

  const [calculations, setCalculations] = useState<CalculationsLibraryType>([])

  useEffect(() => {
    let isMounted = true

    getAllCalculations()
      .then((res) => {
        if (isMounted) {
          setCalculations(res)
          setIsLoading(false)
        }

        return null
      })
      // eslint-disable-next-line no-console
      .catch((e) => console.log(e))
    return () => {
      isMounted = false
    }
  }, [])
  // prettier-ignore
  return (
    <PageLayout breadcrumbs={breadcrumbs}>
      <h1 className="text-3xl text-gray-900 font-semibold pb-10">
        Awell Score Browser
      </h1>
      <div className="py-4">
        <div
          className="mb-4 flex rounded-lg bg-blue-50 p-4 text-sm text-blue-800"
          role="alert"
        >
          <svg
            className="mr-3 mt-[2px] inline h-4 w-4 flex-shrink-0"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
          </svg>
          <span className="sr-only">Info</span>
          <div>
            <p className="mb-4">
              All the information on this website is intended for
              informational/educational purpose and for healthcare professionals
              only. It should not be used for medical diagnosis or treatment or
              to substitute clinical judgement.
            </p>
            <p className="mb-4">
              Although these questionnaires are based on published clinical
              literature, Awell Score cannot guarantee the accuracy of them. The
              healthcare professional is fully responsible for medical
              decision-making and should double-check the calculations and
              formulas.
            </p>
            <p>
              Awell Health cannot and will not be held legally, financially, or
              medically responsible for decisions made using its questionnaires,
              calculations and algorithms.
            </p>
          </div>
        </div>
      </div>
      <Panel>
        <div className="px-6 pb-3 sm:px-8">
          <label htmlFor="filter_calculations" className="sr-only">
            {t("pages.calculations_list.filter")}
          </label>
          <div className="flex rounded-md shadow-sm">
            <div className="relative flex-grow focus-within:z-10">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <svg
                  className="h-5 w-5 text-gray-400"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg>
              </div>
              <input
                id="filter_calculations"
                data-cy="search-calculation-input"
                onChange={(e) =>
                  setFilter({ calculationNameSearchString: e.target.value })
                }
                placeholder={`${t(
                  "pages.calculations_list.search_in_string.prefix"
                )} ${isLoading ? "..." : Object.keys(calculations).length} ${t(
                  "pages.calculations_list.search_in_string.suffix"
                )}`}
                className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-l-md text-gray-800 placeholder-gray-400"
              />
            </div>
            <button
              className="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 text-right rounded-r-md bg-gray-100"
              type="button"
            >
              <svg
                className="h-5 w-5 text-gray-500"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
                />
              </svg>
              <span className="ml-2 text-gray-500">Filter</span>
            </button>
          </div>
        </div>
        {isLoading ? (
          <div className="flex justify-center items-center h-64">
            <LoadingSpinner size="md" />
          </div>
        ) : (
          <div className="flex flex-col mt-2 pb-8">
            <CalculationsTable calculations={calculations} filter={filter} />
          </div>
        )}
      </Panel>
    </PageLayout>
  );
}
