/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { isEmpty } from 'ramda'

import { Alert } from '../../components/Alert/Alert'
import { Button } from '../../components/Button/Button'
import { PageLayout } from '../../components/Layout/PageLayout'
import { LoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner'
import { api } from '../../config/api'
import { LanguageContext } from '../../context/LanguageContext'
import { getCalculation, uploadCsv } from '../../services/calculation.service'
import type {
  ApiErrorType,
  CalculationType,
  CsvUploadedResponseType
} from '../../types/types'
import { viewLocalizedText } from '../../utils/viewLocalizedText'

export const CalculationCsvPage: React.FC = (): React.ReactElement => {
  const { calculationId } = useParams<{ calculationId: string }>()
  const { userLanguage } = useContext(LanguageContext)

  const { t } = useTranslation()

  const [isLoading, setIsLoading] = useState(true)
  const [csvFile, setCsvFile] = useState<File>()
  const [calculation, setCalculation] = useState<CalculationType>()
  const [error, setError] = useState<ApiErrorType>({})
  const [isCalculating, setIsCaclulating] = useState(false)
  const [downloadData, setDownloadData] = useState<CsvUploadedResponseType>()

  const csvData = new FormData()
  if (csvFile) {
    csvData.append('file', csvFile)
  }

  useEffect(() => {
    let isMounted = true

    getCalculation(calculationId)
      .then((res) => {
        if (isMounted) {
          setCalculation(res)
          setIsLoading(false)
        }

        return null
      })
      // eslint-disable-next-line no-console
      .catch((e) => console.log(e))
    return () => {
      isMounted = false
    }
  }, [calculationId])

  const handleCsvUpload = () => {
    setIsCaclulating(true)
    uploadCsv(calculationId, csvData)
      .then((res) => {
        setError({})
        setIsCaclulating(false)
        setDownloadData(res)
        return true
      })
      .catch((e) => {
        const { errorData } = e
        setIsCaclulating(false)
        setError(errorData)
        setDownloadData(undefined)
      })
  }

  if (isLoading)
    return (
      <div className='bg-white overflow-hidden flex justify-center h-screen rounded-lg'>
        <div className='w-11/12 max-w-4xl py-8 flex justify-center items-center'>
          <LoadingSpinner size='lg' />
        </div>
      </div>
    )

  const breadcrumbs = [
    {
      url: '/',
      text: 'Scores'
    },
    {
      url: `/calculations/${calculationId}`,
      text: viewLocalizedText({
        fallback: '',
        label: calculation?.calculation_name,
        language: userLanguage
      })
    },
    {
      url: '',
      text: 'Upload csv'
    }
  ]

  return (
    <PageLayout breadcrumbs={breadcrumbs}>
      <div className='bg-white shadow overflow-hidden rounded-lg'>
        <div className='bg-white px-4 py-6 border-b border-gray-100 sm:px-6 lg:flex lg:items-center lg:justify-between'>
          <div className='flex-1 min-w-0'>
            <h3 className='text-2xl leading-6 font-medium text-gray-900'>
              Upload csv file -{' '}
              {viewLocalizedText({
                fallback: '',
                label: calculation?.calculation_name,
                language: userLanguage
              })}
            </h3>
          </div>
        </div>
        <form>
          <div className='flex flex-col mt-4 pb-8'>
            <div className='mx-8'>
              <Alert
                type='info'
                title='Information'
                content={t('csv.information')}
              />
              {!isEmpty(error) && (
                <div className='mt-4' data-cy='calculation-error'>
                  <Alert type='error' title={error.message} />
                </div>
              )}
              <div className='py-8 text'>
                <div>
                  <label className='w-64 flex flex-col items-center px-4 py-6 bg-white text-blue-400 rounded-lg tracking-wide uppercase border border-blue-400 cursor-pointer hover:bg-blue-400 hover:text-white'>
                    <svg
                      className='w-8 h-8'
                      fill='currentColor'
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 20 20'
                    >
                      <path d='M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z' />
                    </svg>
                    <span className='mt-2 text-base leading-normal'>
                      Select a .csv file
                    </span>
                    <input
                      type='file'
                      name='file'
                      accept='.csv'
                      className='hidden'
                      onChange={(e) => {
                        const target = e.target as HTMLInputElement
                        const file: File = (target.files as FileList)[0]
                        setCsvFile(file)
                      }}
                    />
                  </label>
                  {csvFile && (
                    <span className='text-xs text-gray-600'>
                      {csvFile?.name}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='px-8 py-4 bg-gray-50 text-right'>
            <div
              className='inline-flex justify-center'
              data-cy='execute-calculation-button'
            >
              {downloadData && (
                <div className='mr-2'>
                  <Button
                    label={t('buttons.download_results')}
                    onClick={() =>
                      window.open(
                        `${api.url}${api.endpoints.DOWNLOAD_CSV_FILE(
                          downloadData?.fileName
                        )}`
                      )
                    }
                    svgIcon={
                      <svg
                        className='w-4 h-4 mr-2 text-gray-500'
                        fill='none'
                        stroke='currentColor'
                        viewBox='0 0 24 24'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          strokeWidth='2'
                          d='M15 12a3 3 0 11-6 0 3 3 0 016 0z'
                        />
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          strokeWidth='2'
                          d='M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z'
                        />
                      </svg>
                    }
                  />
                </div>
              )}
              <Button
                label={t('buttons.process_csv')}
                type='submit'
                isLoading={isCalculating}
                onClick={() => handleCsvUpload()}
                color='blue'
                size='normal'
                svgIcon={
                  <svg
                    className='w-4 h-4 mr-2 text-gray-500'
                    fill='none'
                    stroke='#fff'
                    viewBox='0 0 24 24'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth='2'
                      d='M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z'
                    />
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth='2'
                      d='M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
                    />
                  </svg>
                }
              />
            </div>
          </div>
        </form>
      </div>
    </PageLayout>
  )
}
