const height = 8

export const trackStyle = {
  backgroundColor: '#3B82F6',
  height
}

export const handleStyle = {
  borderColor: '#2563EB',
  marginTop: '-8px',
  width: '24px',
  height: '24px',
  backgroundColor: '#2563EB'
}

export const railStyle = { height }
