import React from 'react'

const SVG_CLASSES = 'w-5 h-5'

export const LanguageIcons = {
  nl: (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 512 512'
      className={SVG_CLASSES}
    >
      <mask id='a'>
        <circle cx={256} cy={256} r={256} fill='#fff' />
      </mask>
      <g mask='url(#a)'>
        <path
          fill='#eee'
          d='M0 167l253.8-19.3L512 167v178l-254.9 32.3L0 345z'
        />
        <path fill='#a2001d' d='M0 0h512v167H0z' />
        <path fill='#0052b4' d='M0 345h512v167H0z' />
      </g>
    </svg>
  ),
  en: (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 512 512'
      className={SVG_CLASSES}
    >
      <mask id='a'>
        <circle cx='256' cy='256' r='256' fill='#fff' />
      </mask>
      <g mask='url(#a)'>
        <path
          fill='#eee'
          d='M0 0h47.4l76.4 21 65.4-21h33.4l34.2 16.6L289.4 0h33.4l70.4 22.8L464.8 0h15.8l12.2 7.3L512 0v47.3l-19.9 78 19.9 63.9v33.4l-16.4 30.6 16.4 36.2v33.4l-15.1 68.7 15.1 73.3v15.9l-7.8 10.9L512 512h-47.3l-71-17.5-70.9 17.5h-33.4l-30-19.7-36.8 19.7h-33.3l-63.7-20.2L47.3 512H31.4l-10.6-8L0 512v-47.3l22.8-79L0 322.9v-33.4l25.3-32L0 222.6v-33.4l22.2-64.6L0 47.2V31.4l8-7.8z'
        />
        <path
          fill='#0052b4'
          d='M47.4 0l141.8 142V0H47.4zm275.4 0v142l142-142h-142zM0 47.2v142h142L0 47.2zm512 .1L370.1 189.2H512V47.3zM0 322.8v141.9l141.9-141.9H0zm370 0l142 142v-142H370zM189.3 370l-142 142h142V370zm133.5.1V512h141.9L322.8 370.1z'
        />
        <path
          fill='#d80027'
          d='M222.6 0v222.6H0v66.8h222.6V512h66.8V289.4H512v-66.8H289.4V0h-66.8z'
        />
        <path
          fill='#d80027'
          d='M0 0v31.4l157.7 157.8h31.5L0 0zm480.6 0L322.8 157.7v31.5L512 0h-31.4zM189.2 322.8L0 512h31.4l157.8-157.7v-31.5zm133.6 0L511.9 512h.1v-31.3L354.3 322.8h-31.5z'
        />
      </g>
    </svg>
  ),
  fr: (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 512 512'
      className={SVG_CLASSES}
    >
      <mask id='a'>
        <circle cx='256' cy='256' r='256' fill='#fff' />
      </mask>
      <g mask='url(#a)'>
        <path fill='#eee' d='M167 0h178l25.9 252.3L345 512H167l-29.8-253.4z' />
        <path fill='#0052b4' d='M0 0h167v512H0z' />
        <path fill='#d80027' d='M345 0h167v512H345z' />
      </g>
    </svg>
  ),
  de: (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 512 512'
      className={SVG_CLASSES}
    >
      <mask id='a'>
        <circle cx={256} cy={256} r={256} fill='#fff' />
      </mask>
      <g mask='url(#a)'>
        <path fill='#ffda44' d='M0 345l256.7-25.5L512 345v167H0z' />
        <path fill='#d80027' d='M0 167l255-23 257 23v178H0z' />
        <path fill='#333' d='M0 0h512v167H0z' />
      </g>
    </svg>
  ),
  it: (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 512 512'
      className={SVG_CLASSES}
    >
      <mask id='a'>
        <circle cx='256' cy='256' r='256' fill='#F0F0F0' />
      </mask>
      <g mask='url(#a)'>
        <path
          fill='#F0F0F0'
          d='M167 0h178l25.9 252.3L345 512H167l-29.8-253.4z'
        />
        <path fill='#6DA544' d='M0 0h167v512H0z' />
        <path fill='#D80027' d='M345 0h167v512H345z' />
      </g>
    </svg>
  )
}
